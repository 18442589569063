import PreviewBlock from '@/components/scripts/PreviewBlock/index.vue'
import { mapState } from 'vuex'

export default {
  name: 'GlassesTypes',

  components: {
    PreviewBlock
  },

  asyncData ({store}) {
    return store.dispatch('getBlocks', 'grey')
  },

  computed: {
    ...mapState({
      boxContents (state) {
        return state.blocks[this.blockType] ? state.blocks[this.blockType] : []
      }
    })
  },

  data () {
    return {
      blockType: 'grey'
    }
  },

  mounted () {
    this.$store.dispatch('getBlocks', this.blockType)
  }
}
